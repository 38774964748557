import { default as indexI9z0dnhtUsMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/about-company/index.vue?macro=true";
import { default as _91slug_93knwwUOq0l1Meta } from "/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/actions/[slug].vue?macro=true";
import { default as index8yFiWdyiV7Meta } from "/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/actions/index.vue?macro=true";
import { default as _91slug_93qxbRKz0piMMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/blog/[slug].vue?macro=true";
import { default as indexLJ6kabDzyOMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/blog/index.vue?macro=true";
import { default as _91slug_93mAs0wfI12iMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/bluda/[slug].vue?macro=true";
import { default as indexTvE8aEpxo4Meta } from "/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/bluda/index.vue?macro=true";
import { default as status29F2RjX7fxMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/cabinet/payment/status.vue?macro=true";
import { default as indexxEM3IX2dT7Meta } from "/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/cart/index.vue?macro=true";
import { default as successhfW0pGpldcMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/cart/success.vue?macro=true";
import { default as _91slug_93Rht9DHxqoKMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/categories/[slug].vue?macro=true";
import { default as indexBrOG32TyqOMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/categories/index.vue?macro=true";
import { default as checking_45partnersDr8OQtE2t5Meta } from "/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/checking-partners.vue?macro=true";
import { default as contactsP3HvNokwN1Meta } from "/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/contacts.vue?macro=true";
import { default as index7y8Rq3PDPAMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/delete-account/index.vue?macro=true";
import { default as indexzf7RY4HNGfMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/index.vue?macro=true";
import { default as indexvcHCRE4IQgMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/info-for-partners/index.vue?macro=true";
import { default as _91slug_93mvlPgJFPntMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/kuhni/[slug].vue?macro=true";
import { default as indexgZnwoeg56EMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/kuhni/index.vue?macro=true";
import { default as offerCnYu8kGl9BMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/offer.vue?macro=true";
import { default as indexEmL5ipt2e8Meta } from "/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/partner-apply-from-mobile/index.vue?macro=true";
import { default as privacyWy7C8zbHhDMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/privacy.vue?macro=true";
import { default as addresesCnJhUg27jOMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/profile/addreses.vue?macro=true";
import { default as indexrp68VrZGCbMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/profile/index.vue?macro=true";
import { default as infoKsZnYtZS83Meta } from "/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/profile/info.vue?macro=true";
import { default as reviewsigm8PvkDedMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/profile/reviews.vue?macro=true";
import { default as _91slug_93yXGzQuJSmOMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/restaurants/[slug].vue?macro=true";
import { default as indexBYIZ7ZntKlMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/restaurants/index.vue?macro=true";
import { default as searchruCHD1UBDzMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/search.vue?macro=true";
import { default as standarts_45partnersvSeHCAtGwCMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/standarts-partners.vue?macro=true";
import { default as terms_45of_45useN1A4gF8OGCMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/terms-of-use.vue?macro=true";
import { default as indexz9Fqmd9c0oMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/wish/index.vue?macro=true";
export default [
  {
    name: "about-company",
    path: "/about-company",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/about-company/index.vue").then(m => m.default || m)
  },
  {
    name: "actions-slug",
    path: "/actions/:slug()",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/actions/[slug].vue").then(m => m.default || m)
  },
  {
    name: "actions",
    path: "/actions",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/actions/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "bluda-slug",
    path: "/bluda/:slug()",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/bluda/[slug].vue").then(m => m.default || m)
  },
  {
    name: "bluda",
    path: "/bluda",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/bluda/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-payment-status",
    path: "/cabinet/payment/status",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/cabinet/payment/status.vue").then(m => m.default || m)
  },
  {
    name: "cart",
    path: "/cart",
    meta: indexxEM3IX2dT7Meta || {},
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: "cart-success",
    path: "/cart/success",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/cart/success.vue").then(m => m.default || m)
  },
  {
    name: "categories-slug",
    path: "/categories/:slug()",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/categories/[slug].vue").then(m => m.default || m)
  },
  {
    name: "categories",
    path: "/categories",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: "checking-partners",
    path: "/checking-partners",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/checking-partners.vue").then(m => m.default || m)
  },
  {
    name: "contacts",
    path: "/contacts",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: "delete-account",
    path: "/delete-account",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/delete-account/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "info-for-partners",
    path: "/info-for-partners",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/info-for-partners/index.vue").then(m => m.default || m)
  },
  {
    name: "kuhni-slug",
    path: "/kuhni/:slug()",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/kuhni/[slug].vue").then(m => m.default || m)
  },
  {
    name: "kuhni",
    path: "/kuhni",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/kuhni/index.vue").then(m => m.default || m)
  },
  {
    name: "offer",
    path: "/offer",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/offer.vue").then(m => m.default || m)
  },
  {
    name: "partner-apply-from-mobile",
    path: "/partner-apply-from-mobile",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/partner-apply-from-mobile/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "profile-addreses",
    path: "/profile/addreses",
    meta: addresesCnJhUg27jOMeta || {},
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/profile/addreses.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexrp68VrZGCbMeta || {},
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-info",
    path: "/profile/info",
    meta: infoKsZnYtZS83Meta || {},
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/profile/info.vue").then(m => m.default || m)
  },
  {
    name: "profile-reviews",
    path: "/profile/reviews",
    meta: reviewsigm8PvkDedMeta || {},
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/profile/reviews.vue").then(m => m.default || m)
  },
  {
    name: "restaurants-slug",
    path: "/restaurants/:slug()",
    meta: _91slug_93yXGzQuJSmOMeta || {},
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/restaurants/[slug].vue").then(m => m.default || m)
  },
  {
    name: "restaurants",
    path: "/restaurants",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/restaurants/index.vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "standarts-partners",
    path: "/standarts-partners",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/standarts-partners.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: "wish",
    path: "/wish",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241015015320/pages/wish/index.vue").then(m => m.default || m)
  }
]